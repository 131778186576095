.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    z-index: 1;

}

.popupMessage{
    font-size: 1.5rem;
    /* text-align: center; */
}
.popup-inner {
    position: relative;
    padding: 2rem;
    width: 100%;
    min-width:45rem ;
    max-width:fit-content;
    background-color: white;
    border-radius: 1em;
    border: 10px solid #f7ab61;


}

.popup-inner.close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
}


.popup-inner .bottomButtons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 1em;

}

.buttons {
    background-color: white;
    border-radius: 12%;
    font-weight: bold;
    font-size: 1rem;;
}

.buttons:hover{
    background-color: bisque;
}