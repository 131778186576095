.reportsContainer {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 80%;
    gap: 10%;
    color: #561309;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
}


.buttonAndLabale {
    display: flex;
    gap: 2%;
    align-items: center;
}

.labelButton {
    width: 20rem;
}

.ContainerForReports {
    border-top: 2mm ridge #f7ab61;
}

.subReportContainer {
    display: flex;
    /* border: 2px solid black; */
    /* margin: auto; */
    width: 60%;
}


.tableParams {
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    /* margin: auto; */
}

.reportOutputTitleContainer {
    display: flex;
    /* position: relative; */
    justify-content:center;
    
}

.titleInfoContainer {
    display: flex;
    flex-direction: row;
    /* margin: auto; */
    gap: 40px;
    justify-content:center;

}

.logoInReportContainer {
    display: inline-block;
    /* left:20%; */
    max-width: 15%;
    /* max-height:20%; */
}

.logoInReport {
    max-width: 100%;
    max-height: 100%;
    /* position: absolute;
    z-index: 1000;
    top: 20px;
    left: 300px; */
    /* margin-right: 0 !important; 
    margin-left: 0 !important;  */
}

/* .logoInReport {
    max-width: 80%;
    max-height: 80%;
    position: absolute;
    z-index: 1000;
    top: 20px;
    left: 300px;
   
}*/

.reportsFilters {
    display: flex;
    /* border: 2px solid black; */
    /* justify-content: center; */

    width: 50%;
    gap: 5%;

}

.reportsOutputButtons {
    display: flex;
    flex-direction: column;
    width: 50%;
    /* border: 10px solid red; */
    justify-content: center;
    margin: auto;



}

.ClearTextwithHover {
    display: flex;
    gap: 5px;
}

.r {
    border: 5px solid #561309;
    color: #561309;
    width: 20%;
    display: flex;
    justify-content: center;
    border-radius: 10%;
}

#Hovertext {
    font-size: 0.85em;
    font-style: italic;
    padding-right: 5px;
    color: gray;
}

#growers {
    display: flex;
    gap: 0px;

}

#checkboxActive {
    display: flex;
    gap: 5px;


}

.printDate {
    display: flex;
    font-size: 0.8rem;
    text-align: center;
    flex-direction: column;

}

.datepickerContainerReport {
    border: 1px solid black;
    font-size: 0.8rem;
}

.datepick{
    width: 99%;
}
@media print {

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    font,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
        font-size: 0.8rem !important;
        direction: rtl;
    }

}