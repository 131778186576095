/* html {
  height: 100%;
}

body {
  height: 100%;
}

div#root {
 

  padding: 0 30px;
}

div.app {
  height: 100%;
  
} */


/* .App {
  text-align: center;
  font-family: 'Varela Round', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body{
    font-family: 'Varela Round', sans-serif;
    font-size: 1.25rem; 
}


.mainPageContainer{
    width: 100%;
}


.container{
    max-width: 100%;
}


.navContainer {
    display: flex;
    border-bottom: 4mm ridge #f7ab61;
    justify-content: space-between;
    padding-right: 0%;
}


.img_seasson{
    display: flex;
    justify-content: space-between;
    margin:0;
    width: 30%;
}


.div_imp {
    height: 4em;
    width: 15em;
    right: 0;
}


.logo {
    max-width:100%;
    max-height:100%;
    margin-right: 0 !important; 
    margin-left: 0 !important; 
}


.navContainerButtons_titles {
    display: flex;
    width: 25%;
    justify-content: space-between;
}


.navButton {
    font-weight: bold;
    font-size: 2rem; 
    background-color: white;
    border: 2px solid white;
    color: #f7ab61;
    padding:0;
    text-align: center;
 }


.title_button{
    color: #561309
}


.logOutContainer{
    align-self:center;
}


.season, .logout{
    color: #561309 !important;
    width: 100%;
    align-self:center;
}

.seasonEdit {
    display: flex;
}


.subMainPage {
    display: flex;
}


.extraOptions {
    border-left: 4mm ridge #f7ab61;
    width:15%;
    text-align: right;
}


.update_container {
    margin-right: 1em;
    font-size: 1.3rem; 
    text-align: right;
}


.updateButtons {
    background-color: white;
    border: 2px solid white;
}


.detailsPage {
    width: 85%; 
    /* min-height:790px; */
    height: 100%;
}


.popupMessage {
    font-size: 1.8rem;
} 
body {
  font-family: 'Varela Round', sans-serif;
}


.updatesMain {
  display: flex;
  flex-direction: column;
  grid-gap: 0.3em;
  gap: 0.3em;
}



.tableName {
  font-weight: bold;
  color: #561309;
  text-align: center;
  font-size: 1.8rem;
  margin-top: 1em;
}

.tableNameAction{
  margin-top: 0rem;
}


.changeData {
  text-align: right;
}


.borderData {
  border-top: 2mm ridge #f7ab61;

}


.viewData {
  display: flex;
  flex-direction: column;
  grid-gap: 0.3em;
  gap: 0.3em;
  text-align: right;
  font-size: 1rem;

}


.addButton {
  background-color: white;
  color: black;
  font-size: 1.8rem;
  border: 2px solid white
}


.addButton:hover {
  color: #f7ab61;
}


.addButton:disabled {
  color: white;
}


.tableData {
  text-align: right;
  font-weight: normal;
  font-size: 1rem;
}


.edit_del_buttons {
  display: flex;
  flex-direction: row;
}


.iconButton {
  background-color: transparent;
  font-size:1.5rem;
  border: 0px solid white;
  border-radius:50%;
  color: gray;
}


.iconButton:hover {
  background-color:#f7ab61;
}


.checkbox_is_Active {
  -webkit-transform: scale(1);
          transform: scale(1);
  float: center;
  margin: 0 auto;
}



.cost_button {
  background: linear-gradient(to bottom right,#f7ab61, #c2660a);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
  font-family: 'Varela Round', sans-serif;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.cost_button:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.cost_button:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}




.Edit_form {
  width: 100%;
}


.editButton {
  border-radius: 1rem;
  font-size: 1rem;
  background-color: white;
  color: black;
  border: 2px solid gray;
  font-weight: bold;
  width: 100%;
}

.editButton:hover {
  background-color: #f7ab61;
}

.inputText {
  border: 1px solid white
}

.editInput{
  width: 100%;
}


.tableData_changes{
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 1rem;
  align-items:flex-start;
  flex-direction: column;
  grid-gap:0.5em;
  gap:0.5em;
}


.editButtons_container{
  display: flex;
  grid-gap:0.5em;
  gap:0.5em;
  margin-right: 0.5em; 
}


.detailsData{
  border-top: 2mm ridge #f7ab61;
}
.title {
    font-size: 100px;
    font-family: 'Varela Round', sans-serif;
    text-align: center;
    color: #f7ab61;
    font-weight: bold;
}


.logo {

    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 10%; */
    height: 80%;
    width: 80%;
}


.div_imp_season {
    height:5em;
    width: 15em;
    right: 0;
}

.dropItem {
    font-size:1.5rem;

}


.pickSeason {
    display: flex;
    font-family: 'Varela Round', sans-serif;
    grid-gap: 0.5em;
    gap: 0.5em;
    flex-direction: row;
    font-size: 3.4rem;
    justify-content: center;
}


.dropList {
    border-radius: 0.75rem;
    border-color: white;
    font-size: 3.125rem;
}

.login_container{
    display:flex;
    flex-direction:column;
    /* margin: 10px; */

   
}

.login_container_data{
    display:flex;
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    grid-gap: 50%;
    gap: 50%;
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    z-index: 1;

}

.popupMessage{
    font-size: 1.5rem;
    /* text-align: center; */
}
.popup-inner {
    position: relative;
    padding: 2rem;
    width: 100%;
    min-width:45rem ;
    max-width:-webkit-fit-content;
    max-width:-moz-fit-content;
    max-width:fit-content;
    background-color: white;
    border-radius: 1em;
    border: 10px solid #f7ab61;


}

.popup-inner.close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
}


.popup-inner .bottomButtons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    grid-gap: 1em;
    gap: 1em;

}

.buttons {
    background-color: white;
    border-radius: 12%;
    font-weight: bold;
    font-size: 1rem;;
}

.buttons:hover{
    background-color: bisque;
}
.tableDataInfo {
  border-spacing: 0;
  margin-right: 0.8em;
  margin-left: 0.8em;
 }


.aggregation{
   position: relative;
  padding-right:10% ;
  padding-left:10% ;
  margin: auto;

 } 


.tableData_th {
  margin: 0 0.8em;
  border-bottom: 2px solid black;
  font-size: 1rem;
  vertical-align: top;
}


.tableData_td {
  margin: 0.8 0.8em;
  border-bottom: 1px solid gray;
  font-size: 1rem;
  padding-left: 0.8em;

}


.tableofInfo>tr:hover {
  background-color: bisque;
}



tbody>.trChanges:hover {
  background-color: #ffffff;
}


.pagination {
  font-size: 1rem;
}


.table_title_filter {
  display: flex;
  flex-direction: row;
}


.new-tooltip span {
  visibility: hidden;
  font-size: 1rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0.7em;
  padding: 0.7em 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}


.new-tooltip:hover span {
  visibility: visible;
}


.filterButton {
  background-color: white;
  color: black;
  border: 1px solid white;
}


.unfilterButton {
  background-color: white;
  color: black;
  border: 1px solid white;
  font-size: 1.25rem;
}


.tableData_td>input,
.tableData_td>select,
.react-datepicker__input-container>input {
  border: 1px solid white;
  font-size: 1rem;
}


.tableData_th> span> .unfilterButton {
 text-align:center;
 width: 100%;
}


.filterContainer{
  display: flex;
  grid-gap: 0.05em;
  gap: 0.05em;
}

.reportsContainer {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 80%;
    grid-gap: 10%;
    gap: 10%;
    color: #561309;
}

.column {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
}


.buttonAndLabale {
    display: flex;
    grid-gap: 2%;
    gap: 2%;
    align-items: center;
}

.labelButton {
    width: 20rem;
}

.ContainerForReports {
    border-top: 2mm ridge #f7ab61;
}

.subReportContainer {
    display: flex;
    /* border: 2px solid black; */
    /* margin: auto; */
    width: 60%;
}


.tableParams {
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    /* margin: auto; */
}

.reportOutputTitleContainer {
    display: flex;
    /* position: relative; */
    justify-content:center;
    
}

.titleInfoContainer {
    display: flex;
    flex-direction: row;
    /* margin: auto; */
    grid-gap: 40px;
    gap: 40px;
    justify-content:center;

}

.logoInReportContainer {
    display: inline-block;
    /* left:20%; */
    max-width: 15%;
    /* max-height:20%; */
}

.logoInReport {
    max-width: 100%;
    max-height: 100%;
    /* position: absolute;
    z-index: 1000;
    top: 20px;
    left: 300px; */
    /* margin-right: 0 !important; 
    margin-left: 0 !important;  */
}

/* .logoInReport {
    max-width: 80%;
    max-height: 80%;
    position: absolute;
    z-index: 1000;
    top: 20px;
    left: 300px;
   
}*/

.reportsFilters {
    display: flex;
    /* border: 2px solid black; */
    /* justify-content: center; */

    width: 50%;
    grid-gap: 5%;
    gap: 5%;

}

.reportsOutputButtons {
    display: flex;
    flex-direction: column;
    width: 50%;
    /* border: 10px solid red; */
    justify-content: center;
    margin: auto;



}

.ClearTextwithHover {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}

.r {
    border: 5px solid #561309;
    color: #561309;
    width: 20%;
    display: flex;
    justify-content: center;
    border-radius: 10%;
}

#Hovertext {
    font-size: 0.85em;
    font-style: italic;
    padding-right: 5px;
    color: gray;
}

#growers {
    display: flex;
    grid-gap: 0px;
    gap: 0px;

}

#checkboxActive {
    display: flex;
    grid-gap: 5px;
    gap: 5px;


}

.printDate {
    display: flex;
    font-size: 0.8rem;
    text-align: center;
    flex-direction: column;

}

.datepickerContainerReport {
    border: 1px solid black;
    font-size: 0.8rem;
}

.datepick{
    width: 99%;
}
@media print {

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    font,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
        font-size: 0.8rem !important;
        direction: rtl;
    }

}
